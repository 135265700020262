export type State = {
  menuShowed: boolean;
  gainCalculatorModalShowed: boolean;
};
export const HEADER_SET_MENU_SHOWED = "HEADER_SET_MENU_SHOWED";

export const GAIN_CALCULATOR_MODAL = "GAIN_CALCULATOR_MODAL";

export type HeaderSetMenuShowedAction = {
  type: typeof HEADER_SET_MENU_SHOWED;
  payload: boolean;
};
export type HeaderSetGainCalculatorModal = {
  type: typeof GAIN_CALCULATOR_MODAL;
  payload: boolean;
};

export type HeaderActionTypes =
  | HeaderSetMenuShowedAction
  | HeaderSetGainCalculatorModal;
