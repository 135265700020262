import {
  State,
  HEADER_SET_MENU_SHOWED,
  HeaderActionTypes,
  GAIN_CALCULATOR_MODAL,
} from "./types";

const initialState: State = {
  menuShowed: false,
  gainCalculatorModalShowed: false,
};

export default function headerReducer(
  state = initialState,
  action: HeaderActionTypes
) {
  switch (action.type) {
    case GAIN_CALCULATOR_MODAL:
      return {
        ...state,
        gainCalculatorModalShowed: action.payload,
      };
    case HEADER_SET_MENU_SHOWED:
      return {
        ...state,
        menuShowed: action.payload,
      };

    default:
      return state;
  }
}
