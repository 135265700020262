import { SendOrderWpArgsType, SendOrderWpResponseType } from "./types";

export const api = async (
  args: SendOrderWpArgsType
): Promise<SendOrderWpResponseType> => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${process.env.WP_TOKEN}`);

    const url: string = `${process.env.WP_API_DOMAIN}/wp-json/wc/v3/orders`;

    // FIXME: product_id: 14,
    const raw = JSON.stringify(args);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const answer = await fetch(url, requestOptions);

    if (!answer.ok) {
      throw new Error(answer.status.toString());
    }

    const responseJson: SendOrderWpResponseType = await answer.json();
    return responseJson;
  } catch (e) {
    console.warn("Не удалось отправить данные", e);

    throw e;
  }
};
