/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css";
import "./src/styles/index";

import { WrapWithProvider } from "./src/Providers/wrap-root-element";
export const wrapRootElement = WrapWithProvider;
