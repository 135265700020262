const ROOT = "/";

const PRODUCTS = `${ROOT}products/`;
const CONTACT_US = `${ROOT}contact-us/`;
const RESELLERS = `${ROOT}page-resellers/`;
const MONTE_GT = `${PRODUCTS}monte-gt/`;
const MONTE_GTR = `${PRODUCTS}monte-gtr/`;
const CHIPS_COMPARE = `${PRODUCTS}chips-compare/`;

const ABOUT = `${ROOT}about/`;
const OUR_PRODUCTS = `${ABOUT}our-products/`;
const CART = `${ROOT}cart/`;
const STORE = `${ROOT}store/`;
const NEWS = `${ROOT}news/`;

const SUPPORT = `${ROOT}support/`;
const SUPPORT_GTR = `${SUPPORT}gtr/`;
const SUPPORT_WARRANTY = `${SUPPORT}warranty/`;
const SUPPORT_INSTALLATION = `${SUPPORT}installation/`;

const SUPPORT_ADJUSTMENT = `${SUPPORT}adjustment/`;

const PRIVACY = `${ROOT}privacy`;
const GTAPPS = `${ROOT}gtapps`;
const MANUAL = `${ROOT}manual`;

const SUCCESS_PAYMENT = `${ROOT}success-payment/`;
const ERROR_PAYMENT = `${ROOT}error-payment/`;
const REVIEWS = `${ROOT}reviews/`;

const STATIC_PAGES = [
  "products/monte-gt",
  "products/monte-gtr",
  "reviews",
  "about/our-products",
  "cart",
  "store",
  "news",
  "about",
  "contact-us",
  "page-resellers",
  "support",
  "support/gtr",
  "support/warranty",
  "support/installation",
  "support/adjustment",
  "privacy",
  "gtapps",
  "manual",
];

export {
  STATIC_PAGES,
  ROOT,
  PRODUCTS,
  MONTE_GT,
  MONTE_GTR,
  CHIPS_COMPARE,
  REVIEWS,
  OUR_PRODUCTS,
  CART,
  STORE,
  NEWS,
  ABOUT,
  CONTACT_US,
  RESELLERS,
  SUPPORT,
  SUPPORT_GTR,
  SUPPORT_WARRANTY,
  SUPPORT_INSTALLATION,
  SUPPORT_ADJUSTMENT,
  PRIVACY,
  GTAPPS,
  MANUAL,
  SUCCESS_PAYMENT,
  ERROR_PAYMENT,
};
