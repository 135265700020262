import { products } from "../constants";
import { ProductResponse } from "../api/implementation/products";
import { Product } from "../types/product";
import { getProductDataById } from "./getProductDataById";

export const mapProductsFromApi = (
  productsResp: ProductResponse[]
): Product[] => {
  return productsResp.map((product) => {
    const curProduct = products.find((p) => p.id === product.id);
    return {
      ...product,
      id: product.id,
      title: product.title,
      //HOTFIX price
      // price: 249,
      price: product.price,
      pageLink: curProduct?.pageLink,
      img: curProduct?.img,
      hp: {
        percent: product.hp.percent,
        maxIncrease: product.hp.max_increase,
      },
      nm: {
        percent: product.nm.percent,
        maxIncrease: product.nm.max_increase,
      },
    };
  });
};
