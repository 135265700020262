exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-carslist-index-tsx": () => import("./../../../src/pages/carslist/index.tsx" /* webpackChunkName: "component---src-pages-carslist-index-tsx" */),
  "component---src-pages-cart-index-tsx": () => import("./../../../src/pages/cart/index.tsx" /* webpackChunkName: "component---src-pages-cart-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-gtapps-index-tsx": () => import("./../../../src/pages/gtapps/index.tsx" /* webpackChunkName: "component---src-pages-gtapps-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mainnew-index-tsx": () => import("./../../../src/pages/mainnew/index.tsx" /* webpackChunkName: "component---src-pages-mainnew-index-tsx" */),
  "component---src-pages-manual-index-tsx": () => import("./../../../src/pages/manual/index.tsx" /* webpackChunkName: "component---src-pages-manual-index-tsx" */),
  "component---src-pages-order-cancel-index-tsx": () => import("./../../../src/pages/order-cancel/index.tsx" /* webpackChunkName: "component---src-pages-order-cancel-index-tsx" */),
  "component---src-pages-order-error-index-tsx": () => import("./../../../src/pages/order-error/index.tsx" /* webpackChunkName: "component---src-pages-order-error-index-tsx" */),
  "component---src-pages-order-success-index-tsx": () => import("./../../../src/pages/order-success/index.tsx" /* webpackChunkName: "component---src-pages-order-success-index-tsx" */),
  "component---src-pages-page-resellers-index-tsx": () => import("./../../../src/pages/page-resellers/index.tsx" /* webpackChunkName: "component---src-pages-page-resellers-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-products-chips-compare-index-tsx": () => import("./../../../src/pages/products/chips-compare/index.tsx" /* webpackChunkName: "component---src-pages-products-chips-compare-index-tsx" */),
  "component---src-pages-products-monte-gt-index-tsx": () => import("./../../../src/pages/products/monte-gt/index.tsx" /* webpackChunkName: "component---src-pages-products-monte-gt-index-tsx" */),
  "component---src-pages-products-monte-gt-new-index-tsx": () => import("./../../../src/pages/products/monte-gt-new/index.tsx" /* webpackChunkName: "component---src-pages-products-monte-gt-new-index-tsx" */),
  "component---src-pages-products-monte-gtr-index-tsx": () => import("./../../../src/pages/products/monte-gtr/index.tsx" /* webpackChunkName: "component---src-pages-products-monte-gtr-index-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-slug-index-tsx": () => import("./../../../src/pages/[...slug]/index.tsx" /* webpackChunkName: "component---src-pages-slug-index-tsx" */),
  "component---src-pages-store-index-tsx": () => import("./../../../src/pages/store/index.tsx" /* webpackChunkName: "component---src-pages-store-index-tsx" */),
  "component---src-pages-support-adjustment-index-tsx": () => import("./../../../src/pages/support/adjustment/index.tsx" /* webpackChunkName: "component---src-pages-support-adjustment-index-tsx" */),
  "component---src-pages-support-gtr-index-tsx": () => import("./../../../src/pages/support/gtr/index.tsx" /* webpackChunkName: "component---src-pages-support-gtr-index-tsx" */),
  "component---src-pages-support-installation-index-tsx": () => import("./../../../src/pages/support/installation/index.tsx" /* webpackChunkName: "component---src-pages-support-installation-index-tsx" */),
  "component---src-pages-support-warranty-index-tsx": () => import("./../../../src/pages/support/warranty/index.tsx" /* webpackChunkName: "component---src-pages-support-warranty-index-tsx" */),
  "component---src-pages-test-index-tsx": () => import("./../../../src/pages/test/index.tsx" /* webpackChunkName: "component---src-pages-test-index-tsx" */)
}

