import { MONTE_GT, MONTE_GTR } from "../routes";
import { postReviewType } from "../types";
import { productIds } from "../types/product";

export const localStorageKeyCart = "cart";

export const apiRoutes = {
  promocode: "/api/v1/promo/",
};

const size = {
  break_xs: "576px",
  break_sm: "768px",
  break_md: "992px",
  break_lgx: "1030px",
  break_lgs: "1200px",
  break_lgm: "1920px",
};
export const device = {
  xsBlock: `screen and (min-width: ${size.break_xs})`,
  smBlock: `screen and (min-width: ${size.break_sm})`,
  mdBlock: `screen and (min-width: ${size.break_md})`,
  lgxBlock: `screen and (min-width: ${size.break_lgx})`,
  lgsBlock: `screen and (min-width: ${size.break_lgs})`,
  lgmBlock: `screen and (min-width: ${size.break_lgm})`,
};

export const NODE_TYPES = {
  Post: `Post`,
  Slug: `Slug`,
  Brand: `Brand`,
  Model: `Model`,
  Engine: `Engine`,
  Year: `Year`,
  Author: `Author`,
  Asset: `Asset`,
} as const;

export const CACHE_KEYS = {
  Timestamp: `timestamp`,
} as const;

export const ERROR_CODES = {
  GraphQLSourcing: `10000`,
} as const;

export const products = [
  {
    id: productIds.gt,
    title: "Monte GT",
    descr: "Engine tuning and acceleration meter on your smartphone",
    // цену теперь запрашиваем с сервера и кладём в стор
    price: 1,
    power: "20",
    fuelSaving: "10",
    pageLink: MONTE_GT,
    img: "/img/productGT.png",
    hp: {
      percent: 13,
      maxIncrease: 33,
    },
    nm: {
      percent: 13,
      maxIncrease: 46,
    },
    featuresList: [
      {
        id: "featuresList1",
        text: "Up to +20%* HP +20%* NM",
      },
      {
        id: "featuresList2",
        text: "+10%* fuel saving",
      },
      {
        id: "featuresList3",
        text: "6 driving modes",
      },
      {
        id: "featuresList4",
        text: "IOS / Android apps",
      },
      {
        id: "featuresList5",
        text: "",
      },
      {
        id: "featuresList6",
        text: "",
      },
      {
        id: "featuresList7",
        text: "",
      },
      {
        id: "featuresList8",
        text: "",
      },
      {
        id: "featuresList9",
        text: "",
      },
    ],
  },
  {
    id: productIds.gtr,
    title: "Monte GTR",
    descr:
      "Tune your engine and display car readings on 3.5 inch touch screen display.",
    // цену теперь запрашиваем с сервера и кладём в стор
    price: 1,
    power: 20,
    fuelSaving: 18,
    pageLink: MONTE_GTR,
    img: "/img/productGTR.png",
    hp: {
      percent: 13,
      maxIncrease: 33,
    },
    nm: {
      percent: 13,
      maxIncrease: 46,
    },
    featuresList: [
      {
        id: "productGTRFeatureUpTo",
        text: "Up to +20%* HP +20%* NM",
      },
      {
        id: "productGTRFeatureFuelSave",
        text: "+10% fuel saving",
      },
      {
        id: "productGTRFeatureModes",
        text: "6 driving modes",
      },
      {
        id: "productGTRFeaturePc",
        text: "PC programmer",
      },
      {
        id: "productGTRFeatureTouch",
        text: "Touch screen display",
      },
      {
        id: "productGTRFeatureError",
        text: "Error code reader",
      },
      {
        id: "productGTRFeatureGauges",
        text: "Real time gauges",
      },
      {
        id: "productGTRFeatureAcc",
        text: "Acceleration meter",
      },
      {
        id: "productGTRFeatureDyno",
        text: "Virtual dyno",
      },
    ],
  },
  {
    id: 111,
    title: "Test Product",
    descr:
      "Tune your engine and display car readings on 3.5 inch touch screen display.",
    // цену теперь запрашиваем с сервера и кладём в стор
    price: 1,
    power: 20,
    fuelSaving: 10,
    pageLink: null,
    img: "/img/productGTR.png",
    hp: {
      percent: 13,
      maxIncrease: 47,
    },
    nm: {
      percent: 13,
      maxIncrease: 47,
    },
  },
];

export const main = {
  email: "support@montetuning.com",
  phone: "+1 (501) 204-0005",
  address: "1313 North Hills Blvd Suite 309, North Little Rock, AR 72114",
  addressCoors: {
    lat: 34.77125321303456,
    lng: -92.25027433122266,
  },
};

export const pdfManual = {
  english:
    "https://drive.google.com/file/d/1axNmrT79F4mclbWuxqPBb6WvJbTgfYsY/view?usp=drive_link",
  spanish:
    "https://drive.google.com/file/d/1H_cmZffDPdu4N9TjWZE-eSz1rzfAkqN8/view",
};

export const reviewsVotes = [
  {
    rating: 5,
    count: 12,
  },
  // {
  //   rating: 4,
  //   count: 30,
  // },
  // {
  //   rating: 3,
  //   count: 3,
  // },
];

export const newsNodes = [
  {
    meta: {
      date: "09.25.2021",
    },
    targetlink: "https://www.youtube.com/watch?v=YWZk9f8AD9s",
    title: "Abarth Tested with Monte GTR",
    short_description:
      "Watch Abarth tested on the dyno several times with and without Monte GTR.",
    description:
      "Watch Abarth tested on the dyno several times with and without Monte GTR.",
    image: "/img/img-new-1.png",
  },
  {
    meta: {
      date: "09.25.2021",
    },
    targetlink: "https://www.youtube.com/watch?v=ILkxUvJh3Pc",
    title: "GTR tested by Carnews Taiwan",
    short_description:
      "Monte GTR Testeted and explained by the Taiwanese motor journalists.",
    description:
      "Monte GTR Testeted and explained by the Taiwanese motor journalists.",
    image: "/img/img-new-2.png",
  },
  {
    meta: {
      date: "09.25.2021",
    },
    targetlink: "https://www.youtube.com/watch?v=gAe6y6PzWZg&t=2s",
    title: "Abarth Tested with Monte GTR",
    short_description:
      "Watch Abarth tested on the dyno several times with and without Monte GTR.",
    description: "Learn more about Monte GTR and its features.",
    image: "/img/img-new-3.png",
  },
  {
    meta: {
      date: "09.25.2021",
    },
    targetlink: "https://www.youtube.com/watch?v=PLmjexxCssc",
    title: "Abarth Tested with Monte GTR",
    short_description:
      "Watch Abarth tested on the dyno several times with and without Monte GTR.",
    description: "Easy installation in less than 5 minutes. Learn how, here",
    image: "/img/img-new-4.png",
  },
];

export const allReviews: postReviewType[] = [
  {
    author: "Ben Smith",
    date: "5/04/2022",
    rating: 5,
    text: "Best value upgrade I’ve made for my car so far. My car feels alive!&. Best value upgrade I’ve made for my car so far. My car feels alive!",
    car: "Dodge Challenger 5.7L 2014",
  },
  {
    author: "Jack R.",
    date: "5/04/2022",
    rating: 4,
    text: "I'm blown away by how much of a difference the Monte GT chip made in my car's performance. It's like driving a completely different car! ",
    car: "Dodge Charger 6.2L 2019",
  },
  {
    author: "Steven O'reilly",
    date: "6/11/2022",
    rating: 4,
    text: "Best value upgrade I’ve made for my car so far. My car feels alive!&. Best value upgrade I’ve made for my car so far. My car feels alive!",
    car: "Toyota Tacoma 3.5L 2020",
  },
  {
    author: "Dean",
    date: "6/23/2022",
    rating: 5,
    text: "Great product! Works well so far. I will update my review after more miles.",
    car: "Toyota 4Runner 2.0L 2021",
  },
  {
    author: "Craig C.",
    date: "7/07/2022",
    rating: 5,
    text: "My Tundra feels sportier, thank you!",
    car: "Toyota Tundra 5.7L 2017",
  },
  {
    author: "John",
    date: "7/15/2022",
    rating: 5,
    text: "Installation was super easy, but you should be mindful about 120 miles of adjustment, other than that very straightforward. Special thanks to Eugene from the monte support. He was very responsive and professional.",
    car: "Ford F-250 Super Duty 6.2L 2021",
  },
  {
    author: "JJ",
    date: "7/29/2022",
    rating: 5,
    text: " ",
    car: "RAM Pro Master 1500 3.6L 2018",
  },
  {
    author: "Alex D.",
    date: "8/01/2022",
    rating: 4,
    text: "Nice packaging and branding, I haven’t given it much testing though",
    car: "Toyota Tacoma 3.5L 2017",
  },
  {
    author: "James Brown",
    date: "8/11/2022",
    rating: 5,
    text: "Very fast shipping! Thanks",
    car: "Ford F150 3.5L 2019",
  },
  {
    author: "Kyle C.",
    date: "8/17/2022",
    rating: 2,
    text: "I wanted to switch to a different car, but it wasn’t on the list!",
    car: "",
  },
];
