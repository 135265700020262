import { combineReducers, applyMiddleware } from "redux";
import { legacy_createStore as createStore } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { thunk } from "redux-thunk";

import headerReducer from "./Header/reducers";

import * as products from "./Products";
import * as cart from "./Cart";
import * as makes from "./Makes";
import * as models from "./Models";
import * as engines from "./Engines";
import * as years from "./Years";
import * as calculatorBrands from "./CalculatorBrands";
import * as calculatorModels from "./CalculatorModels";
import * as calculatorEngines from "./CalculatorEngines";

import * as car from "./Car";
import * as calculatorCar from "./CalculatorCar";

import * as promo from "./Promo";
import * as feedbackPopup from "./FeedbackPopup";

import { cartListener } from "./Cart/listeners";
import { composeWithDevTools } from "@redux-devtools/extension";

const rootReducer = combineReducers({
  products: products.reducer,
  header: headerReducer,
  cart: cart.reducer,
  makes: makes.reducer,
  models: models.reducer,
  engines: engines.reducer,
  years: years.reducer,
  calculatorBrands: calculatorBrands.reducer,
  calculatorModels: calculatorModels.reducer,
  calculatorEngines: calculatorEngines.reducer,

  promo: promo.reducer,
  feedbackPopup: feedbackPopup.reducer,
  car: car.reducer,
  calculatorCar: calculatorCar.reducer,
});

// const composeEnhancers =
//   typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//       })
//     : compose;

export default function configureStore() {
  const middlewares = [thunk];

  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(...middlewares)
      // other store enhancers if any
    )
  );

  store.subscribe(() => cartListener(store));

  return store;
}

export type AppState = ReturnType<typeof rootReducer>;
export type Store = ReturnType<typeof configureStore>;
export type ProductsState = ReturnType<typeof products.selectors.products>;

export {
  products,
  makes,
  engines,
  models,
  years,
  calculatorBrands,
  calculatorModels,
  calculatorEngines,
  cart,
  promo,
  feedbackPopup,
  car,
  calculatorCar,
};
