/**
 * External dependencies.
 */

import React from "react";

import { Provider } from "react-redux";
import createStore from "../store";
import { GatsbyBrowser } from "gatsby";
import { AppProvider } from "./AppProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
// import { clientWoo } from "../apollo/client-woo";

/**
 * Internal dependencies.
 */

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */

export const WrapWithProvider: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  console.log("wrapRootElement");
  const store = createStore();
  // create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AppProvider>{element}</AppProvider>
      </Provider>
    </QueryClientProvider>
  );
};
