import { SendReviewArgsType, SendReviewResponseType } from "./types";

export const api = async (
  args: SendReviewArgsType
): Promise<SendReviewResponseType> => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${process.env.WP_TOKEN}`);

    const url: string = `${process.env.WP_API_DOMAIN}/wp-json/wc/v3/products/reviews`;

    // FIXME: product_id: 14,
    const raw = JSON.stringify({
      product_id: 14,
      review: args.comment,
      reviewer: args.name,
      reviewer_email: args.email,
      rating: args.voitRate,
      status: "hold",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const answer = await fetch(url, requestOptions);

    if (!answer.ok) {
      throw new Error(answer.status.toString());
    }

    const responseJson: SendReviewResponseType = await answer.json();
    return responseJson;
  } catch (e) {
    console.warn("Не удалось отправить данные", e);

    throw e;
  }
};
