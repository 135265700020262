import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsState, products } from "../store";

export const AppContext = React.createContext([{}, () => {}]);

type Props = {
  children: string | JSX.Element | JSX.Element[] | any;
};

export const AppProvider = ({ children }: Props) => {
  const [cart, setCart] = useState<any>({});
  // console.log("AppProvider");
  // useEffect(() => {
  //   if (isBrowser) {
  //     let cartData = localStorage.getItem("woo-next-cart");
  //     cartData = null !== cartData ? JSON.parse(cartData) : "";
  //     setCart(cartData);
  //   }
  // }, []);
  const dispatch = useDispatch();
  const productsState: ProductsState = useSelector(products.selectors.products);

  // FIXME вызывется 2 раза
  useEffect(() => {
    if (!productsState.loading && productsState.data?.length > 0) {
      return;
    }
    if (productsState.error) {
      console.log(productsState.error);
    }
    dispatch(products.actions.fetchProducts());
    // console.log("AppProvider", productsState);
  }, []);

  return (
    <AppContext.Provider value={[cart, setCart]}>
      {children}
    </AppContext.Provider>
  );
};
